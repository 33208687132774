/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.w-1em {
  width: 1em;
}

.w-2em {
  width: 2em;
}

.w-4em {
  width: 4em;
}
.w-6em {
  width: 6em;
}

.w-7em {
  width: 7em;
}


.w-8em {
  width: 8em;
}

.w-10em {
  width: 10em;
}

.w-16em {
  width: 16em;
}

.w-32em {
  width: 32em;
}

